import * as React from 'react';
import { IVideoPlayerHandles, IVideoPlayerProps } from '../VideoPlayer.types';

import { useAsyncRef } from '../../../providers/useAsyncRef';
import VideoPlayers from './players';
import { IPlayerHandles } from './players/players.types';
import { st, classes } from './style/VideoPlayer.st.css';

import { getPlayerName } from './utils';

const VideoPlayer: React.ForwardRefRenderFunction<
  IVideoPlayerHandles,
  IVideoPlayerProps
> = (props: IVideoPlayerProps, ref) => {
  const { onMouseEnter, onMouseLeave } = props;
  const [reloadId, setReloadId] = React.useState<number>(0);
  const playerName = getPlayerName(props.src);
  const isPlayable = playerName === 'playable';
  const Player = VideoPlayers[playerName];

  const reloadPlayer = React.useCallback(() => setReloadId(reloadId + 1), [
    reloadId,
  ]);

  const playerRef = React.useRef<IPlayerHandles>(null);
  const [waitForPlayer, getPlayer, setPlayer] = useAsyncRef<IPlayerHandles>();

  React.useImperativeHandle(ref, () => ({
    play: () => waitForPlayer().then(player => player.play()),
    pause: () => waitForPlayer().then(player => player.pause()),
    stop: () =>
      waitForPlayer().then(player =>
        player.stop ? player.stop() : reloadPlayer(),
      ),
    togglePlay: () => waitForPlayer().then(player => player.togglePlay()),
    mute: () => waitForPlayer().then(player => player.mute()),
    unmute: () => waitForPlayer().then(player => player.unMute()),
    seek: (timePoint: number) =>
      waitForPlayer().then(player => player.seekTo(timePoint)),
    setVolume(volume: number) {
      const player = getPlayer();

      if (player) {
        player.setVolume(volume);
      }
    },
  }));

  const updateCorvidState = () => {
    const player = getPlayer();
    if (!props.updateState || !player) {
      return;
    }

    props.updateState({
      isPlaying: player.isPlaying(),
      duration: player.getDuration(),
      currentTime: player.getCurrentTime(),
      volume: player.getVolume(),
      isMuted: player.isMuted(),
    });
  };

  const handleDuration = updateCorvidState;
  const handleReady = updateCorvidState;
  const handlePlay = () => {
    updateCorvidState();
    props.onPlay?.();
  };

  const handlePause = () => {
    updateCorvidState();
    props.onPause?.();
  };

  const handleEnded = () => {
    updateCorvidState();
    props.onEnded?.();
  };

  const handleProgress = () => {
    updateCorvidState();
    props.onProgress?.();
  };

  const handleInit = () => {
    setPlayer(playerRef.current as IPlayerHandles);
    updateCorvidState();
  };
  return (
    <div
      id={props.id}
      className={st(classes.root)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Player
        key={`player-${props.src}-${reloadId}`}
        {...props}
        {...(isPlayable && props.playableConfig)}
        playing={props.autoplay}
        onProgress={handleProgress}
        onDuration={handleDuration}
        onInit={handleInit}
        onReady={handleReady}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handleEnded}
        ref={playerRef}
      />
    </div>
  );
};

export default React.forwardRef(VideoPlayer);
